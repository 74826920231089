<template>
  <section
    id="menu"
    :class="!isMenuOpen ? '-translate-x-full' : 'translate-x-0'"
    class="fixed bottom-0 left-0 top-0 z-50 flex w-9/12 max-w-72 flex-col space-y-8 overflow-hidden rounded-r-sidebar bg-blue-500 p-5 pt-12 transition-transform md:hidden"
  >
    <h2 class="break-words text-3xl font-semibold text-white">
      {{ content.ext.greeting }}
      <span v-if="isSignedIn" class="ml-1 text-3xl font-semibold"> {{ profile?.firstName }}</span
      >!
    </h2>

    <p class="max-w-[40ch] text-base font-light text-white" v-html="tipTextMobile"></p>

    <nav class="h-full" :class="{ hidden: !isMenuOpen }" aria-label="Main">
      <SiteNavItem
        v-if="isSignedIn"
        name="Dashboard"
        icon="dashboard"
        to="/dashboard"
        @click="$emit('close-menu')"
      />

      <SiteNavItem
        v-else
        name="Save your progress"
        icon="touch"
        to="#"
        @click.prevent="signUpRedirect()"
        @click="$emit('close-menu')"
      />

      <SiteNavItem
        v-if="isSignedIn"
        name="Sign out"
        icon="logout"
        to="#"
        @click.prevent="signOut"
        @click="$emit('close-menu')"
      />

      <SiteNavItem
        v-else
        name="Sign in"
        icon="login"
        to="#"
        @click.prevent="signInRedirect()"
        @click="$emit('close-menu')"
      />
    </nav>

    <HpIcon
      name="healthpilot-logo-icon"
      class="relative -bottom-14 -right-12 w-80 text-white opacity-10"
    />
  </section>
</template>

<script lang="ts" setup>
  import { ProfileStore } from '~/stores/profile'
  import { SessionStore } from '~/stores/session'

  defineProps({
    isMenuOpen: {
      type: Boolean,
      required: true
    }
  })

  const { flag } = FeatureFlags.use()
  const isAep = computed(() => flag('is-aep', false).value)

  const { getComponentContent } = Cms.use()

  const { content } = await getComponentContent('SiteNav')

  const { isAuthenticated, signOut, signUpRedirect, signInRedirect } = Auth.use()
  const isSignedIn = ref(isAuthenticated.value)
  watch(isAuthenticated, () => (isSignedIn.value = isAuthenticated.value))

  const profile = ProfileStore.use()

  const tipTextMobile = computed(() => content.value.ext.tip_text_mobile)

  const session = SessionStore.use()

  const { onMessage } = ChatController.use()
  onMessage('talkdesk', (ev) => {
    session.unreadMessages = ev.unread
  })
</script>

<style scoped>
  :deep(strong) {
    @apply font-semibold text-white;
  }
</style>
