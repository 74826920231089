<template>
  <header class="sticky top-0 z-40 bg-white px-5 py-2" @keydown.esc="closeDropdown">
    <nav class="mx-auto flex w-full max-w-[--pageWidth] items-center justify-between">
      <!-- Mobile back button -->
      <HpButton
        variant="minimal"
        icon="back"
        icon-classes="w-8 text-primary-600"
        icon-location="left"
        class="block w-4 md:hidden"
        :class="{ invisible: isStartPage }"
        @click="back"
      >
        <span class="sr-only">Go back</span>
      </HpButton>

      <div class="flex-1">
        <HpAnchor
          @click.prevent="() => maybeDo(() => push('/'))"
          class="flex w-full justify-center md:w-fit"
        >
          <LogoSvg class="hidden sm:flex sm:w-[11.5rem]" />
          <MobileLogoSvg class="relative left-2 w-12 sm:hidden" />
        </HpAnchor>
      </div>

      <HpAnchor
        href="tel:{{ phoneNumber }}"
        class="hidden flex-1 whitespace-nowrap text-gray-900 no-underline md:flex md:flex-col md:items-center"
      >
        <span>
          <span class="mr-1 font-semibold text-primary">{{ phoneNumber }}</span>
          | TTY 711
        </span>
        <span class="whitespace-nowrap text-xs font-semibold text-gray-800 lg:text-sm" v-if="isAep">
          Mon - Fri: 8am-9pm CST | Sat - Sun: 9am-6pm CST
        </span>
        <span class="text-sm font-semibold text-gray-800" v-else>
          Monday - Friday | 9am - 6pm, Central
        </span>
      </HpAnchor>

      <div class="relative md:flex-1">
        <HpButton
          variant="secondary"
          :icon="headerCTA.icon"
          icon-classes="w-6"
          icon-location="left"
          class="ml-auto hidden w-fit md:block"
          @click.prevent="headerCTA.action"
        >
          {{ headerCTA.label }}
        </HpButton>

        <!-- Desktop Dropdown Menu -->
        <ul
          ref="dropdownMenu"
          class="absolute right-1 top-full hidden w-40 origin-top scale-y-0 overflow-hidden rounded-b-tooltip bg-white shadow-menu transition-transform md:block"
          :class="{ grow: isDropdownOpen, hidden: !isDropdownOpen }"
        >
          <li class="border-b border-border px-4 pb-2.5 pt-3.5 hover:bg-gray-200">
            <NuxtLink
              to="/dashboard"
              @click="closeDropdown"
              class="flex items-center gap-1 text-base font-semibold text-primary-900 no-underline"
            >
              <HpIcon name="dashboard" class="w-5" />
              Dashboard
            </NuxtLink>
          </li>
          <li class="px-4 py-1 hover:bg-gray-200">
            <HpButton
              variant="minimal"
              class="flex w-fit items-center !text-base !font-semibold !text-primary-900"
              @click.prevent="signOut"
              @click="closeDropdown"
            >
              <HpIcon name="logout" class="w-5" />
              Sign Out
            </HpButton>
          </li>
        </ul>
      </div>

      <div class="relative md:hidden">
        <HpButton variant="minimal" @click="show('talkdesk')" class="absolute -top-1 right-12">
          <HpIcon name="help" class="w-8 text-primary-600" />
          <span class="sr-only">Get Help</span>
        </HpButton>
        <SiteNavHamburger :is-menu-open="isMenuOpen" @click="toggleMenu" @keydown.esc="closeMenu" />
      </div>
    </nav>
  </header>

  <!-- Mobile Menu -->
  <client-only>
    <SiteNav :is-menu-open="isMenuOpen" @close-menu="closeMenu" />
  </client-only>

  <!-- Page backdrop for open mobile menu -->
  <div
    v-if="isMenuOpen"
    @click="closeMenu"
    class="fixed inset-0 z-40 grid place-content-center md:hidden"
  ></div>
</template>

<script lang="ts" setup>
  import LogoSvg from '~/assets/images/healthpilot.svg?component'
  import MobileLogoSvg from '~/assets/images/healthpilot-icon.svg?component'
  import { onClickOutside } from '@vueuse/core'
  import { SessionStore } from '~/stores/session'
  import {PlanType} from "~/generated/api-clients-generated";

  const { show } = ChatController.use()
  const session = SessionStore.use()
  const { isAuthenticated, signOut, signUpRedirect, signInRedirect } = Auth.use()
  const isSignedIn = ref(isAuthenticated.value)
  const planType = ref<PlanType | null>()
  watch(isAuthenticated, (val) => (isSignedIn.value = val))
  const { getComponentContent } = Cms.use()
  const contactCardContent = await getComponentContent('HelperCardPageCompare')
  const { flag } = FeatureFlags.use()
  const isAep = computed(() => flag<boolean>('is-aep').value)

  const { isStartPage } = AppRoute.use()

  const { back, push } = AppRouter.use()

  const { maybeDo } = ActionGuard.use()
  const { path } = AppRoute.use()

 const isApplying = computed(() => {
   return  path.value === '/apply'
 })

  const headerCTA = computed(() => {
    switch (true) {
      case isSignedIn.value:
        return {
          label: 'My Account',
          icon: 'account',
          action: () => toggleDropdown()
        }
      case isStartPage.value && !isSignedIn.value:
        return {
          label: 'Sign in',
          icon: 'account',
          action: () => signInRedirect()
        }
      default:
        return {
          label: 'Save your progress',
          icon: 'touch',
          action: () => maybeDo(() => signUpRedirect())
        }
    }
  })

  const isMenuOpen = ref(false)

  const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value
  }

  const closeMenu = () => {
    isMenuOpen.value = false
  }

  const isDropdownOpen = ref(false)

  const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value
  }

  const closeDropdown = () => {
    isDropdownOpen.value = false
  }

  watch(isMenuOpen, () => {
    isMenuOpen.value
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto')
  })

  const dropdownMenu = ref(null)

  onClickOutside(dropdownMenu, () => closeDropdown())
  const phoneNumber = computed(() => {

    console.log('path.value', path.value.includes('/details'))
    switch (true) {
      case  path.value.includes('/apply'):
        return '(855) 208-8559'
      case path.value === '/quotes'
      || path.value.includes('/details')
      || path.value.includes('/quotes')
      || path.value.includes('/compare'):
        switch(true){
          case session.planType ===  PlanType.PDP:
            return contactCardContent.content.value.ext.pdp_tfn
          case session.planType ===  PlanType.MAPD:
            return contactCardContent.content.value.ext.mapd_tfn
          default:
            return contactCardContent.content.value.ext.phone_number
        }
      default:
        return contactCardContent.content.value.ext.phone_number
    }
  })


</script>

<style scoped>
  .grow {
    @apply scale-y-100;
  }
</style>
